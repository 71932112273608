<template>
	<v-row>
		<v-col cols="7">
			<v-card>
				<v-card-title primary-title>
					Tugas
				</v-card-title>
				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td style="width: 130px;"><b>Agenda</b></td>
									<td style="width: 10px;">:</td>
									<td>Hari ke {{ hari_ke }}, {{ formatDate(materi.tanggal) }}</td>
								</tr>
								<tr>
									<td><b>Nama</b></td>
									<td>:</td>
									<td>{{ materi.nama }}</td>
								</tr>
								<tr>
									<td><b>Deskripsi</b></td>
									<td>:</td>
									<td>
										<div v-html="nl2br(materi.deskripsi)"></div>
									</td>
								</tr>
								<tr>
									<td><b>Jenis Tugas</b></td>
									<td>:</td>
									<td>{{ materi.jenis_tugas }}</td>
								</tr>
								<tr>
									<td><b>Pemateri</b></td>
									<td>:</td>
									<td>{{ materi.nama_user }}</td>
								</tr>
								<tr>
									<td><b>File Materi</b></td>
									<td>:</td>
									<td>
										<a :href="materi.file_url" target="_blank">
											Lihat
										</a>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="5">
			<v-card>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<h3>Daftar Peserta Upload Tugas</h3>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Email</th>
									<th>Nama</th>
									<th>File Upload</th>
									<th>Nilai</th>
									<th>Waktu Nilai</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in tableUploaded.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.email }}</td>
									<td>{{ item.nama }}</td>
									<td>
										<v-btn color="success" x-small @click="lihatUploaded(item)">Lihat</v-btn>
									</td>
									<td>{{ item.nilai }}</td>
									<td>{{ formatDateTime(item.dinilai_at) }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<v-layout row justify-center>
						<v-dialog v-model="formUpload.dialog" fullscreen transition="dialog-bottom-transition"
							:overlay="false">
							<v-card>
								<v-toolbar dark color="primary">
									<v-btn icon dark @click="formUpload.dialog = false">
										<v-icon>
											{{ icons.mdiClose }}
										</v-icon>
									</v-btn>
									<v-toolbar-title>Penilaian Tugas</v-toolbar-title>
									<!-- <v-spacer></v-spacer>
									<v-toolbar-items>
										<v-btn dark text @click="dialog = false">
											Save
										</v-btn>
									</v-toolbar-items> -->
								</v-toolbar>
								<v-card-text>
									<v-form>
										<v-container>
											<v-row>
												<v-col cols="12" md="4">
													<v-text-field v-model="formUpload.edit.email" label="Email:"
														outlined readonly hide-details="" dense required></v-text-field>
												</v-col>
												<v-col cols="12" md="4">
													<v-text-field v-model="formUpload.edit.nama" label="Nama:" outlined
														readonly hide-details="" dense required></v-text-field>
												</v-col>
												<v-col cols="12" md="2">
													<v-text-field type="number" v-model="formUpload.edit.nilai" label="Nilai:"
														outlined hide-details="" dense required></v-text-field>
												</v-col>
												<v-col cols="12" md="2">
													<v-btn color="primary" @click="saveNilaiTugas()">Simpan</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</v-form>
									<h3>File Peserta:</h3>
									<iframe class="pdf" :src="formUpload.edit.file_url" width="800" height="500">
									</iframe>
								</v-card-text>
							</v-card>
						</v-dialog>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<style scoped>
table tr td {
	vertical-align: top;
}

.pdf {
	width: 100%;
	aspect-ratio: 4 / 3;
}

.pdf,
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose
			},
			id_pelatihan: this.$route.params.id_pelatihan,
			id_materi: this.$route.params.id_materi,
			hari_ke: this.$route.params.hari_ke,
			pelatihan: {},
			materi: {
				tanggal: '',
				nama: '',
				deskripsi: '',
				jenis_tugas: '',
				nama_user: '',
				file_url: '',
			},
			formUpload: {
				dialog: false,
				editedIndex: -1,
				file_temp: '',
				edit: {},
				default: {}
			},
			tableUploaded: {
				items: []
			},
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getTugasPeserta()
		this.getUploadedTugasPemateri()
	},
	methods: {
		onFileChange() {
			this.formUpload.file_temp = this.$refs.file.files.item(0)
		},
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		getTugasPeserta() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getTugasPeserta', fmData)
				.then(response => {
					this.materi = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		getUploadedTugasPemateri() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			axiosPostAuth('api/Pelatihan/getUploadedTugasPemateri', fmData)
				.then(response => {
					this.tableUploaded.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		lihatUploaded(item) {
			this.formUpload.edit = Object.assign({}, item)
			this.formUpload.dialog = true
		},
		saveNilaiTugas() {
			const fmData = new FormData()
			fmData.append('id_uploaded', this.formUpload.edit.id)
			fmData.append('nilai', this.formUpload.edit.nilai)
			axiosPostAuth('api/Pelatihan/saveNilaiTugas', fmData)
				.then(response => {
					if (response.code === 200) {
						this.getUploadedTugasPemateri()
						this.formUpload.dialog = false
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
	}
}
</script>